import React, {useEffect, useState} from 'react'
import './style.css'
import Logo from '../Images/Logo.png'

function Header({aos_delay, reloadOnClick}) {

  if(typeof aos_delay == (undefined || null)) {
    aos_delay = "0"
  }

  if(reloadOnClick) {
    setTimeout(() => {
      for(let i = 0; i < 3; i++) {
        document.getElementsByClassName("headerItem")[i].addEventListener("click", e => {
          setTimeout(() => window.location.reload(), 200)
        })
      }
    }, 100)
  } 

  const [pageNr, setPageNr] = useState(0);
  
  useEffect(()=> {
    window.scrollTo(0,0)
    document.getElementById("headerRightSide").classList.remove("dropMenuActive")
  },[pageNr])

  return (
    <div id='header' className='mHeader' data-aos="fade-down" data-aos-delay={aos_delay}>

      <label id='toggleDropMenu'>
		 <i className="fa-solid fa-bars"></i>
			  <input type="checkbox" onChange={e => {
				  document.getElementById("headerRightSide").classList.toggle("dropMenuActive")
		}} />

      </label>

          <img src={Logo} alt="" id='headerImg' />
          <div id='headerRightSide' className='dropMenu'>
              <a href='/#/' className='headerItem' onClick={() => setTimeout(() => window.location.reload())}>Home</a>
              <a href='/#/' className='headerItem' onClick={e=> {
                setTimeout(() => document.getElementById("about").scrollIntoView(), 100)
              }}>Über uns</a>
              <a href='/#/' className='headerItem' onClick={e=> {
                setTimeout(() => document.getElementById("Contact").scrollIntoView(), 100)
              }}>Kontakt</a>
          </div>
    </div>
  )
}



export default Header