import React from 'react'
import Header from '../Layout/Header'

function MailSent() {
  return (
  <div id='mailSent'>
        <Header reloadOnClick={true}/>
  <div id='about'>
        <h1 className='pageTitle'>Mail gesendet!</h1>
        <p className='pageDescription'>
            Vielen Dank für Ihre Anfrage! 
            Ihre Anfrage wurde erfolgreich abgeschickt! Wir bitten Sie, etwas geduld zu haben, bis unsere Mitarbeiter 
            Ihr Anliegen bearbeitet haben.
        </p>
      </div>
  </div>
    
  )
}

export default MailSent