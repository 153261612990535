import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './Pages/Home/Home';
import Footer from './Layout/Footer';
import { Route, Routes } from 'react-router';
import { HashRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import MailSent from './Pages/MailSent';
import Impressum from './Pages/Impressum/Impressum';



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <div id='page'>
        <HashRouter>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/mailSent' element={<MailSent />} />
            <Route exact path='/impressum' element={<Impressum />} />
          </Routes>
          </HashRouter>
      </div>
      <Footer/>
  </React.StrictMode>
);


