import React from 'react'
import Logo from '../Images/Logo.png'
import "./style.css"

function Footer() {


	return (
    	<div id='footer' data-aos="fade-up"  data-aos-delay="300">
    		<div id='socials'>
				<a id="instagram" style={{"--hover-bc": "#128C7E"}} href="https://wa.me/491783050115"><i className="fa-brands fa-whatsapp"></i></a>
				<a id="facebook" href="/" style={{"--hover-bc": "#4267B2"}}><i className="fa-brands fa-facebook"></i></a>
				<a id="mail" href="mailto:kontakt@bewusstsein-ev.de"><i className="fa-regular fa-envelope"></i></a>
			</div>  
			
			<div id='footerInline'>
				<div>
					<img src={Logo} alt="" id='footerImg' />
					<p id='copyright'>Hadi - Reinigungsservices © 2023 Alle Rechte vorbehalten.</p>
				</div>
				<div>
				<div className="google-map">
				
    </div>
  </div>				
			</div>
			<div id='footerBottom'>
				<a href='/#/impressum' onClick={() => setTimeout(() => window.location.reload(), 100)}>Impressum & Disclaimer</a>
			</div>		

    </div>
  )
}

export default Footer