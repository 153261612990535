import './style.css';
import Header from '../../Layout/Header';
import reversedLogo from "../../Images/Logo reversed.png"
import bueroReinigungsfoto from "../../Images/Büroreinigung.jpg"
import glasReinigungsfoto from "../../Images/Fensterreinigung.jpg"
import teppichReinigungsfoto from "../../Images/teppichreinigung.jpg"
import bodenversiegelungsFoto from "../../Images/Bodenversiegelung.jpg"
import gebaeudereinigungsFoto from "../../Images/Treppenreinigung.jpg"
import kuechenreinigung from "../../Images/küchenreinigung.jpg"

function Home() {

	

	return (
		<div id='Home'>
			<div id='homeIntro' data-aos="zoom-in">
				<div>
					<img id='headLogo' src={reversedLogo} alt="" data-aos="fade-down" data-aos-delay="300" />
					<hr />
					<p data-aos="fade-up" data-aos-delay="600">
					<i className="fa-solid fa-check"></i> vielfältige & flexible Angebote <br />
					<i className="fa-solid fa-check"></i> passendes, ausgebildetes Personal <br />
					<i className="fa-solid fa-check"></i> Schnell und günstig <br />
					<i className="fa-solid fa-check"></i> 100% Kundenzufriedenheit
					</p>
				</div>

					<div className="scroll"></div>
			</div>

			<Header aos_delay="300"/>

			<div id='about' >
				<h1 className='pageTitle'  data-aos="fade-right" data-aos-delay="300">Über uns</h1>
				<p className='pageDescription'  data-aos="fade-left" data-aos-delay="300">
					Wir sind ein gut ausgebildetes Team mit viel Erfahrung, das darauf spezialisiert ist,
					bestimmte Reinigungsservices durchzuführen. Unten können Sie genauer sehen,
					welche Services wir anbieten und für genauere Details können Sie gerne mit uns 
					über unser Kontakt-Formular, per Email oder telefonisch in Kontakt treten, denn 
					wir sind immer für unsere Kunden da! Außerdem garantieren wir Ihnen Ihre Zufriedenheit
					mit unseren sehr schnellen und günstigen Services. 
				</p>
			</div>

			<div id='services'>
				<h1 className='pageTitle'  data-aos="zoom-in-right" data-aos-delay="100" >Was wir anbieten</h1>
				<div>

					<div className='angebot'  data-aos="fade-right" data-aos-delay="300">
						<div className="angebot_head">
							<img src={bueroReinigungsfoto} alt="" />
							<h2>Büroreinigung</h2>
						</div>
						<div className="angebot_body">
							<p className="description">
								Sie wollen in Ihr Büro reingehen und möchten, dass alles einfach glänzt und ohne 
								dass Sie sich darüber sorgen machen und das übernehmen? Dann sind wir dgenau die richtigen für Sie! <br /><br />
								<i className="fa-solid fa-check"></i> Reinigung der WCs, Böden, Tische und Teppiche <br /> 
								<i className="fa-solid fa-check"></i> Müllentsorgung <br />
								<i className="fa-solid fa-check"></i> Auffüllen von Papierhandtüchern oder Toilettenpapier

							</p>
							<a href="/#/" onClick={e=> setTimeout(() => document.getElementById("Contact").scrollIntoView(), 100)}>zum Kontaktformular</a>
						</div>
					</div>

					<div className='angebot'  data-aos="fade-right" data-aos-delay="300">
						<div className="angebot_head">
							<img src={gebaeudereinigungsFoto} alt="" />
							<h2>Gebäudereinigung</h2>
						</div>
						<div className="angebot_body">
							<p className="description">
								Unsere Aufgabe ist es, für Sie durch perfekte Reinigung des Gebäudes für ein positives 
								äußeres Erscheinigungsbild, für eine Wohlfühl-Atmosphäre und für die Werterhaltung der Immobilie zu sorgen.  <br /><br />
								<i className="fa-solid fa-check"></i> Reinigung Böden, Treppen und Eingänge <br /> 
								<i className="fa-solid fa-check"></i> Müllentsorgung im Treppenhaus <br />
								<i className="fa-solid fa-check"></i> sehr gut ausgebildetes Personal
							</p>
							<a href="/#/" onClick={e=> setTimeout(() => document.getElementById("Contact").scrollIntoView(), 100)}>zum Kontaktformular</a>
						</div>
					</div>

					<div className='angebot'  data-aos="fade-right" data-aos-delay="300">
						<div className="angebot_head">
							<img src={kuechenreinigung} alt="" />
							<h2>Küchenreinigung</h2>
						</div>
						<div className="angebot_body">
							<p className="description">
								Wenn Sie große Küchen haben, allerdings keine Zeit darein stecken möchten, diese gründlich 
								zu reinigen, dann sind wir genau richtig für Sie. Wir bieten eine umfassende Reinigung von 
								Küchen und Großküchen - z.B. für Unternehmen, Restaurants oder auch Privathaushalte - an.
							</p>
							<a href="/#/" onClick={e=> setTimeout(() => document.getElementById("Contact").scrollIntoView(), 100)}>zum Kontaktformular</a>
						</div>
					</div>

					<div className='angebot' data-aos="fade-left" data-aos-delay="300">
						<div className="angebot_head">
							<img src={glasReinigungsfoto} alt="" />
							<h2>Glas- & Fensterreinigung</h2>
						</div>
						<div className="angebot_body">
							<p className="description">
								Falls Sie zu viele Fenster haben und es nicht mehr alleine schaffen, diese zu reinigen,
								dann sind wir perfekt für Sie da! <br /> <br /> 
								<i className="fa-solid fa-check"></i> mit Gerüst <br /> 
								<i className="fa-solid fa-check"></i> mit langen Leitern <br />
								<i className="fa-solid fa-check"></i> + Jalousie- & Lamellenreinigung 
							</p>
							<a href="/#/" onClick={e=> setTimeout(() => document.getElementById("Contact").scrollIntoView(), 100)}>zum Kontaktformular</a>
						</div>
					</div>

					<div className='angebot' data-aos="fade-right" data-aos-delay="500">
						<div className="angebot_head">
							<img src={teppichReinigungsfoto} alt="" />
							<h2>Teppichnassreinigung</h2>
						</div>
						<div className="angebot_body">
							<p className="description">
								Teppichnassreiniung von Teppichfestbelägen mit bestem Werkzeug und den besten Geräten. 
								Der Preis ist abhängig von der Größe des zu reinigenden Teppichs!
							</p>
							<a href="/#/" onClick={e=> setTimeout(() => document.getElementById("Contact").scrollIntoView(), 100)}>zum Kontaktformular</a>
						</div>
					</div>

					<div className='angebot' data-aos="fade-left" data-aos-delay="600">
						<div className="angebot_head">
							<img src={bodenversiegelungsFoto} alt="" />
							<h2>Bodenversiegelung</h2>
						</div>
						<div className="angebot_body">
							<p className="description">
								Versiegelung von PVC und Linoleum-Böden mit bestem Material. Preis nach Absprache feststellbar.
							</p>
							<a href="/#/" onClick={e=> setTimeout(() => document.getElementById("Contact").scrollIntoView(), 100)}>zum Kontaktformular</a>
						</div>
					</div>
				</div>
			</div>

			<div id="Contact" data-aos="fade-down-right" data-aos-delay="500">
				<h1 className='pageTitle'>Kontakt</h1>
				<p className='pageDescription'>
					Mit dem folgenden Kontakt-Formular können Sie direkt mit uns Kontakt 
					aufnehmen und daraufhin werden wir versuchen, Ihr Anliegen sehr schnell 
					wie möglich zu bearbeiten. Alternativ können Sie uns auch unter der Email-Adresse 
					<a href="mailto:kontakt@hadi-reinigungsservices.de" style={{marginInline: 5}}>kontakt@hadi-reinigungsservices.de</a> 
					erreichen.
				</p>		
			

			<div id='formCon'>
				<form action="./php/sendMail.php" method='POST' id="contactForm">
					<p className="lbl">Name:</p>
					<input type="text" className="contactFormTb" placeholder="Name..." name="from_name" required /><br />
 
					<p className="lbl">Email-Adresse:</p>
					<input type="email" className="contactFormTb" placeholder="Email..." name="sender_email" required /><br />
									
					<p className="lbl">Betreff:</p>
					<input type="text" className="contactFormTb" placeholder="Betreff..." name="betreff" required /><br />
								
					<textarea placeholder="Ihre Nachricht..." className="textarea" name="message" required></textarea>
					<input type="submit" id="sendMail" value="Absenden"  />
				</form>
			</div>
			</div>
		</div>
	);
}

export default Home;
